/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import { produce } from "immer";
import _ from "lodash";
import {
    REMOVE_VISITOR,
    UPDATE_VISITOR,
    UPDATE_VISITORS,
    UPDATE_VISITOR_ACTIVITY,
    UPDATE_VISITOR_VISIBILITY
} from "../actions/visitorsActions";

const initialState = {
    visitors: [],
    initialized: false
};

const visitorsReducer = (state = initialState, action) => {

    switch (action.type) {

        case UPDATE_VISITOR_VISIBILITY: {
            const { visitor_id, status } = action.payload;
            return produce(state, draft => {
                let index = _.indexOf(state.visitors, _.find(state.visitors, {'id': visitor_id}));
                if(index !== -1){
                    let tmp = draft.visitors[index];
                    tmp['status'] = status;
                    draft.visitors[index] = tmp;
                }
            })
        }

        case UPDATE_VISITOR_ACTIVITY: {
            const { activity } = action.payload;
            return produce(state, draft => {
                if(state.visitors.filter(v => v.id === activity['visitor']).length === 1){
                    let index = _.indexOf(state.visitors, _.find(state.visitors, {'id': activity['visitor']}));
                    draft.visitors[index]['last_activity'] = activity;
                    // When there is activity of the user, he/she has to be online
                    draft.visitors[index]['status'] = 'Online';
                }
            });
        }

        case UPDATE_VISITORS: {
            const { data } = action.payload;
            return produce(state, draft => {
                for(let i = 0; i < data.length; i++){
                    let index = _.findIndex(state.visitors, {'id': data[i].id});
                    if(index === -1){
                        draft.visitors = draft.visitors.concat([data[i]]);
                    } else {
                        draft.visitors[index] = data[i];
                    }
                }
                // draft.visitors = data;
                draft.initialized = true;
            })
        }

        case UPDATE_VISITOR: {
            const { visitor } = action.payload;
            return produce(state, draft => {
                let index = _.findIndex(state.visitors, {'id': visitor.id});
                if(index !== -1){
                    // TODO: if this ECMA2018 works better then lodash, we should change it everywhere
                    draft.visitors[index] = {...draft.visitors[index], ...visitor};
                    // draft.products[index] = _.merge(draft.products[index], product);
                } else {
                    draft.visitors = state.visitors.concat([visitor]);
                }
            });
        }

        case REMOVE_VISITOR: {
            const { visitorId } = action.payload;
            return produce(state, draft => {
                draft.visitors = state.visitors.filter(v => v.id !== parseInt(visitorId));
            });
        }

        default: {
            return state;
        }
    }
};

export default visitorsReducer;
