/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import { produce } from "immer";
import _ from "lodash";
import {
    UPDATE_ORDER, UPDATE_ORDERS, REMOVE_ORDER,
    UPDATE_SHIPPING_ZONES, UPDATE_SHIPPING_ZONE,
    REMOVE_SHIPPING_ZONE, UPDATE_VOUCHERS, UPDATE_VOUCHER,
    REMOVE_VOUCHER
} from "../actions/ordersActions";

const initialState = {
    orders: [],
    initialized: false,
    zones: null,
    shippingMethods: null,
    vouchers: [],
};

const ordersReducer = (state = initialState, action) => {

    switch (action.type) {

        case UPDATE_VOUCHERS: {
            const { vouchers } = action.payload;
            return produce(state, draft => {
                // replace all zones
                draft.vouchers = vouchers;
            })
        }

        case UPDATE_VOUCHER: {
            const { voucher } = action.payload;
            return produce(state, draft => {
                let index = _.findIndex(state.vouchers, {'id': voucher.id});
                if(index !== -1){
                    // TODO: if this ECMA2018 works better then lodash, we should change it everywhere
                    draft.vouchers[index] = {...draft.vouchers[index], ...voucher};
                } else {
                    draft.vouchers = state.vouchers.concat([voucher]);
                }
            });
        }

        case REMOVE_VOUCHER: {
            const { voucherId } = action.payload;
            return produce(state, draft => {
                draft.vouchers = state.vouchers.filter(v => v.id !== parseInt(voucherId));
            });
        }

        case UPDATE_SHIPPING_ZONES: {
            const { zones } = action.payload;
            return produce(state, draft => {
                // replace all zones
                draft.zones = zones;
            })
        }

        case UPDATE_SHIPPING_ZONE: {
            const { zone } = action.payload;
            return produce(state, draft => {
                let index = _.findIndex(state.zones, {'id': zone.id});
                if(index !== -1){
                    // TODO: if this ECMA2018 works better then lodash, we should change it everywhere
                    draft.zones[index] = {...draft.zones[index], ...zone};
                } else {
                    draft.zones = state.zones.concat([zone]);
                }
            });
        }

        case REMOVE_SHIPPING_ZONE: {
            const { zoneId } = action.payload;
            return produce(state, draft => {
                draft.zones = state.zones.filter(z => z.id !== parseInt(zoneId));
            });
        }

        case UPDATE_ORDERS: {
            const { data } = action.payload;
            return produce(state, draft => {
                // replace all orders
                draft.orders = data;
                // draft.orders = data;
                draft.initialized = true;
            })
        }

        case UPDATE_ORDER: {
            const { order } = action.payload;
            return produce(state, draft => {
                let index = _.findIndex(state.orders, {'id': order.id});
                if(index !== -1){
                    // TODO: if this ECMA2018 works better then lodash, we should change it everywhere
                    draft.orders[index] = {...draft.orders[index], ...order};
                    // draft.products[index] = _.merge(draft.products[index], product);
                } else {
                    draft.orders = state.orders.concat([order]);
                }
            });
        }

        case REMOVE_ORDER: {
            const { orderId } = action.payload;
            return produce(state, draft => {
                draft.orders = state.orders.filter(v => v.id !== parseInt(orderId));
            });
        }

        default: {
            return state;
        }
    }

};

export default ordersReducer;
